import {PropTypes} from "prop-types";
import {useEffect, useRef, useState} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const DEFAULT_TIMEOUT = 300;

const Animate = ({children, transitionClass = "fade", ...rest}) => {
  const ref = useRef();

  return (
    <CSSTransition
      {...rest}
      nodeRef={ref}
      timeout={DEFAULT_TIMEOUT}
      mountOnEnter
      unmountOnExit
      classNames={transitionClass}
    >
      <div ref={ref}>{children}</div>
    </CSSTransition>
  );
};

const Appear = ({children, transitionClass = "fade", ...rest}) => {
  const [isVisible, setVisible] = useState(false);
  const containerRef = useRef();
  const ref = useRef();

  useEffect(() => {
    const el = containerRef.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const callback = entries => {
      entries.forEach(entry => {
        const expectedCondition =
          entry.isIntersecting && entry.intersectionRatio >= 0.75;
        if (expectedCondition) {
          setVisible(true);
          observer.unobserve(el);
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);

    if (el) observer.observe(el);

    return () => {
      if (el) observer.unobserve(el);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <CSSTransition
        {...rest}
        in={isVisible}
        nodeRef={ref}
        timeout={DEFAULT_TIMEOUT}
        mountOnEnter
        unmountOnExit
        classNames={transitionClass}
      >
        <div ref={ref}>{children}</div>
      </CSSTransition>
    </div>
  );
};

const List = ({
  children,
  transitionClass = "fade",
  component = "ul",
  className,
  ...props
}) => {
  const ref = useRef(null);
  return (
    <CSSTransition
      {...props}
      nodeRef={ref}
      classNames={transitionClass}
      timeout={DEFAULT_TIMEOUT}
      mountOnEnter
      unmountOnExit
    >
      <div ref={ref}>
        <TransitionGroup className={className} component={component}>
          {children}
        </TransitionGroup>
      </div>
    </CSSTransition>
  );
};

const ListItem = ({id, transitionClass = "fade", children, ...props}) => {
  const ref = useRef(null);
  return (
    <CSSTransition
      {...props}
      key={id}
      nodeRef={ref}
      timeout={DEFAULT_TIMEOUT}
      classNames={transitionClass}
    >
      <li ref={ref}>{children}</li>
    </CSSTransition>
  );
};

Animate.propTypes = {
  children: PropTypes.node.isRequired,
  transitionClass: PropTypes.string,
};

List.propTypes = {
  in: PropTypes.any,
  children: PropTypes.node,
  transitionClass: PropTypes.string,
  component: PropTypes.any,
  className: PropTypes.string,
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  transitionClass: PropTypes.string,
  id: PropTypes.string,
};

Appear.propTypes = {
  children: PropTypes.node.isRequired,
  transitionClass: PropTypes.string,
  id: PropTypes.string,
  multiple: PropTypes.bool,
};

Animate.Appear = Appear;
Animate.Appear.displayName = "Animate.Appear";

Animate.List = List;
Animate.List.displayName = "Animate.List";

Animate.List.Item = ListItem;
Animate.List.Item.displayName = "Animate.List.Item";

export default Animate;
